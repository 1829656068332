/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef, memo } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MainCard from "../../../../../components/MainCard";
import { CustomButton } from "../../../../../components/ui/Button";
import OrderManagement from "./OrderManagement";
import PaymentManagement from "./PaymentManagement";
import { useDispatch, useSelector } from "react-redux";
import DemandGeneration from "./DemandGeneration";
import OfflineMarketing from "./OfflineMarketingActivities";
import OnlineMarketing from "./OnlineMarketingActivities";
import ForeCastInfo from "./ForeCastInfo/ForeCastInfo";
import MarketIntelligence from "./MarketIntelligence/MarketIntelligence";
// import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import LongTermBusinessSolution from "./LongTermBusinessSolution";
import SalesVolume from "./SalesVolume";
import Authorization from "../../../../../utils/authorization"; 
import {
  setResetCompletiontatus, 
  resetActivityEnable,
  setActivityEnable,
  getOrderManageObj,
} from "../../../../../store/reducers/contracts";
// import util from "util";
import { apiURL } from "../../../../../services/apiUrlConfig";
import SalesVolImg from "../../../../../assets/images/9.svg";
import DemandImg from "../../../../../assets/images/5.svg";
import ForcasetImg from "../../../../../assets/images/3.svg";
import ShoppingImg from "../../../../../assets/images/1.svg";
import MarkertImg from "../../../../../assets/images/4.svg";
import PaymentImg from "../../../../../assets/images/2.svg";
import OnlineImg from "../../../../../assets/images/7.svg";
import OfflineImg from "../../../../../assets/images/6.svg";
import BulpImg from "../../../../../assets/images/8.svg";
import { getRequest } from "../../../../../services/apiUtils";
import { openSnackbar } from "../../../../../components/ui/Toastr";
import { ErrorMsg } from "../../../../../utils/helper";
import MiModal from "../../../../../components/ui/MiModal";
import BrandRepresentation from "./BrandRepresentation";
import POGDelivery from "./POGDelivery/POGDelivery";
import activityBrandRes from "../../../../../assets/images/activityBrandRes.svg";
import activityPOG from "../../../../../assets/images/pog.svg";


export const CompletionStatusTypes = {
  NOTSTARTED: "transparent",
  PENDING: "#FFFF99",
  DONE: "#66B512",
};
const activityImages = {
  1: ShoppingImg,
  2: PaymentImg,
  3: ForcasetImg,
  4: MarkertImg,
  5: DemandImg,
  6: OfflineImg,
  7: OnlineImg,
  8: BulpImg,
  9: SalesVolImg,
  10:activityBrandRes,
  11:activityPOG

};
// const COMPLETIONSTATUS = {
//   SUBMITTED: "#66B512",
//   PENDING: "",
// };
//ListItemText
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "rgb(0, 97, 127)",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  height: "100%",
}));
const ListItemTextStyled = styled(ListItemText)(({ theme }) => ({
  fontSize: "12px !important",
}));

const ActivitiesCriteria = (props) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [activities, setActivities] = useState([]);
  const [initialactivities, setInitialActivities] = useState([]);
  const [selectedActivities, setselectedActivities] = useState([]);
  const [criteriaTitle, setCriteriaTitle] = useState("Order Management");
  const [activityStep, setActivityStep] = useState();
  const [activityEnb, setActivityEnb] = useState();
  const [isLast, setIsLast] = useState(false);
  const [IndexObj, setIndexObj] = useState([]);
 // const [activityType, setActivityType] = useState("CREATE");
  const activitiesList = useSelector(
    (state) => state.activitiesSaga.activitiesList
  );
  const getselectedActivities = useSelector(
    (state) => state.contracts.activitiesList
  );
  const enableActivity = useSelector((state) => state.contracts.activityEnable);

  const SalesVolumeCompletionStat = useSelector(
    (state) => state.contracts.completionStatus
  );
  const accountsSelected = useSelector((state) => state.contracts.accounts);
  const dispatch = useDispatch();
  // const getApi = apiURL.ppp.getActivities ?? "";
  const userDetaisl = Authorization.authUser;
 // const activitisApi = util.format(getApi, userDetaisl.id);
  const navigate = useNavigate();
  const ContractActionType = useSelector(
    (state) => state.common.ContractActionType
  );
  // eslint-disable-next-line no-unused-vars
  const [isLoader, SetLoader] = useState(false);
  const [offlineMarketingOptions, setOfflineMarketingOptions] = useState([]);
  const [onlineMarketingOptions, setOnlineMarketingOptions] = useState([]);
  const [brandRepOptions, setBrandRepOptions] = useState([]);
  const [open, setOpen] = useState(false);

  // const ActivitiesLength = Array(9 - 1 + 1).fill().map((_, index) => 1 + index)
  const ref = useRef(null);
  useEffect(() => {
    const statusEnb =
      // eslint-disable-next-line eqeqeq
      Object.keys(enableActivity).length != 0
        ? enableActivity[activityStep] ?? true
        : true;
    const lastEl = Math.max(...getselectedActivities.map((o) => o.id));

    if (lastEl === activityStep) {
      setIsLast(true);
    } else {
      setIsLast(false);
    }
    setActivityEnb(statusEnb);
  }, [enableActivity, activityStep]);

  useEffect(() => {
    dispatch(setResetCompletiontatus());
    if (ContractActionType.type === "EDIT") {
      accountsSelected?.length > 0 &&
        // eslint-disable-next-line array-callback-return
        accountsSelected.map((itm) => {
          dispatch(
            setActivityEnable({
              id: itm.activitytypeid,
              enable: itm.isdefined === null ? true : false,
            })
          );
          dispatch(
            getOrderManageObj({
              status: itm.isdefined === null ? "INPROGRESS" : "DONE",
              id: itm.activitytypeid,
            })
          );
        });
    } else {
      dispatch(resetActivityEnable());
    }
  }, []);

  useEffect(() => {
    // dispatch(FETCH_REQUEST(activitisApi))
    getLoadMarketingActvityTypeData();
  }, []);

  useEffect(() => {
    setInitialActivities(activitiesList);
  }, [activitiesList]);

  useEffect(() => {
    const seleItms =
      getselectedActivities &&
      getselectedActivities.map((itm) => {
        return itm.id;
      });
    setselectedActivities(seleItms);
  }, [getselectedActivities]);

  useEffect(() => {
    if (selectedActivities?.length > 0 && initialactivities?.length > 0) {
      const seleItms =
        activitiesList &&
        initialactivities.map((itm) => {
          if (selectedActivities.includes(itm.id)) {
            return Object.assign({}, itm, { disable: false });
          } else {
            return Object.assign({}, itm, { disable: true });
          }
        });

      setActivities(seleItms);
      initialIndex(seleItms);
    }
  }, [selectedActivities, initialactivities]);

  const initialIndex = (seleItms) => {
    const ids = seleItms.map((itm) => {
      if (itm.disable === false) {
        return itm.id;
      } else {
        return 999;
      }
    });
    const indexSel = Math.min(...ids);
    setSelectedIndex(indexSel > 0 ? indexSel : 0);
    setActivityStep(indexSel);
    setCriteriaTitle(activities[indexSel]?.name);
  };
  useEffect(() => {
    const getindex = activities&&activities.filter(itm => itm.id === activityStep)
    setCriteriaTitle(getindex[0]?.name);
  }, [activityStep, activities]);
 
  const nextAvailableActivity = (val) => {
    if (activityStep === 5 && val?.length === 0 && !open) {
      setOpen(true);
    } else {
      let IndexPsh = [...IndexObj];
      for (const element of activities) {
        if (element?.disable === false&&element?.id > activityStep) {
          setActivityStep(element?.id);
          setCriteriaTitle(element?.name);
          setSelectedIndex(Number(element?.id));
          IndexPsh.push(element?.id);
          break;
        }
      }
      setIndexObj(IndexPsh);

      if (isLast) {
        navigate("/createContracts");
      }
    }
  };

  const nextActivity = () => {
    switch (activityStep) {
      case 1:
        if (activityEnb) {
          ref.current.submitOrderDetails();
        } else {
          nextAvailableActivity();
        }
        break;
      case 2:
        if (activityEnb) {
          ref.current.submitPaymentDetails();
        } else {
          nextAvailableActivity();
        }
        break;
      case 3:
        if (activityEnb) {
          ref.current.submitForeCastInfo();
        } else {
          nextAvailableActivity();
        }
        break;
      case 4:
        if (activityEnb) {
          ref.current.submitMi();
        } else {
          nextAvailableActivity();
        }
        break;
      case 5:
        if (activityEnb) {
          ref.current.moveToNextActivity();
        } else {
          nextAvailableActivity();
        }
        break;
      case 6:
        if (activityEnb) {
          ref.current.submitOfflineMA();
        } else {
          nextAvailableActivity();
        }
        break;
      case 7:
        if (activityEnb) {
          ref.current.submitOnlineMA();
        } else {
          nextAvailableActivity();
        }
        break;
      case 8:
        if (activityEnb) {
          ref.current.submitLongTerm();
        } else {
          nextAvailableActivity();
        }
        break;
      case 9:
        if (activityEnb) {
          ref.current.submitSalesVolumeDetails();
        } else {
          nextAvailableActivity();
        }
        break;
        case 10:
        if (activityEnb) {
          ref.current.submitBrandRep();
        } else {
          nextAvailableActivity();
        }
        break;
        case 11:
        if (activityEnb) {
          ref.current.submitPOGDelivery();
        } else {
          nextAvailableActivity();
        }
        break;
        default : break;
    }
  };

  const handleListItemClick = async(event, index, al) => {
    if (!al.disable) {
      await setSelectedIndex(al?.id);
      await setCriteriaTitle(al?.name);
      await setActivityStep(al.id);
    }
  };

  useEffect(()=>{
    const handleTabClose = event => {
      event.preventDefault();
      return(event.returnValue = 'Are you want to exit?');
    }
    window.addEventListener('beforeunload',handleTabClose)
     return()=>{
       window.removeEventListener('beforeunload',handleTabClose)
     }
    
  },[]) 

  const modalProceed = () => {
    setOpen(false);
    nextAvailableActivity();
  };
  const modalCancel = () => {
    setOpen(false);
  };

  const renderNext = (value) => {
    switch (activityStep) {
      case 1:
        return (
          <OrderManagement
            id={activityStep}
            nextActivity={() => nextAvailableActivity()}
            ref={ref}
          />
        );
      case 2:
        return (
          <PaymentManagement
            id={activityStep}
            nextActivity={() => nextAvailableActivity()}
            ref={ref}
          />
        );
      case 5:
        return (
          <DemandGeneration
            id={activityStep}
            ref={ref}
            nextActivity={(val) => nextAvailableActivity(val)}
          />
        );
      case 6:
        return (
          <OfflineMarketing
            id={activityStep}
            nextActivity={() => nextAvailableActivity()}
            options={offlineMarketingOptions}
            ref={ref}
          />
        );
      case 7:
        return (
          <OnlineMarketing
            id={activityStep}
            nextActivity={() => nextAvailableActivity()}
            options={onlineMarketingOptions}
            ref={ref}
          />
        );
      case 8:
        return (
          <LongTermBusinessSolution
            id={activityStep}
            nextActivity={() => nextAvailableActivity()}
            ref={ref}
          />
        );
      case 9:
        return (
          <SalesVolume
            nextActivity={() => nextAvailableActivity()}
            ref={ref}
            id={activityStep}
          />
        );
      case 10:
        return (
          <BrandRepresentation
            nextActivity={() => nextAvailableActivity()}
            ref={ref}
            id={activityStep}
            options={brandRepOptions}
          />
        );
        case 11:
          return (
            <POGDelivery
              nextActivity={() => nextAvailableActivity()}
              ref={ref}
              id={activityStep}
            />
          );
      case 3:
        return (
          <ForeCastInfo
            nextActivity={() => nextAvailableActivity()}
            ref={ref}
            id={activityStep}
          />
        );
      case 4:
        return (
          <MarketIntelligence
            nextActivity={() => nextAvailableActivity()}
            ref={ref}
            id={activityStep}
          />
        );

      default:
        break;
    }
  };

  const getLoadMarketingActvityTypeData = () => {
    const {
      ppp: { activities },
    } = apiURL;
    SetLoader(true);
    getRequest({
      url: `${activities?.getMarketingActivityType}/${userDetaisl.id}`,
    })
      .then((response) => {
        let result = response?.body;
        let offline = [];
        let online = [];
        let brandRep=[]
        result.forEach((rt) => {
          let obj = { value: rt?.id, label: rt?.name, mode: rt?.mode };
          if (rt.mode === "ONLINE") {
            online.push({ ...obj });
          } else {
            offline.push({ ...obj });
          }
          brandRep.push({...obj})
        });
        setOfflineMarketingOptions([...offline]);
        setOnlineMarketingOptions([...online]);
        setBrandRepOptions([...brandRep])
      })
      .catch((error) => {
        const errorRes = ErrorMsg(error);
        openSnackbar({ message: errorRes, variant: "error" });
      });
  };
  return (
    <div className="activities-criteria">
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2} height={"75vh"}>
          <Grid item xs={3}>
            <Item>
              <List>
                {activities &&
                  activities.map((al, index) => (
                    <ListItem key={al.name} disablePadding>
                      <ListItemButton
                        sx={{
                          "&.Mui-selected": {
                            backgroundColor: "#3780a5",
                          },
                          gap: 1,
                        }}
                        style={{ opacity: !al.disable ? 1 : 0.4 }}
                        selected={selectedIndex === al?.id}
                        onClick={(event) =>
                          handleListItemClick(event, index, al)
                        }
                      >
                        <ListItemIcon>
                          {/* <FiberManualRecordIcon sx={{ backgroundColor:SalesVolumeCompletionStat,fontSize:"1rem"}} ></FiberManualRecordIcon> */}
                          <Box
                            sx={{ width: 10, height: 10 }}
                            style={{
                              margin: 5,
                              borderRadius: 5,
                              backgroundColor:
                                CompletionStatusTypes[
                                  SalesVolumeCompletionStat[al.id]
                                ],
                            }}
                          ></Box>
                          {/* {al?.icon} */}
                          <img src={activityImages[al.id]} alt={al.name} />
                        </ListItemIcon>
                        <ListItemTextStyled
                          disableTypography={true}
                          style={{ fontSize: "12px !important" }}
                          primary={al.name}
                          sx={{ color: "#F7F9FA" }}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
              </List>
            </Item>
          </Grid>
          <Grid item xs={9}>
            <MainCard
              title={criteriaTitle}
              elevation={0}
              border={false}
              content={true}
              headerSX={{ backgroundColor: "#00617f3b", color: "#323232" }}
              secondary={
                <Box
                  display="flex"
                  justifyContent={"space-around"}
                  alignItems="center"
                  sx={{ width: "100%", gap: 1 }}
                >
                  {activityStep === "Offline_Marketing_Activities" ||
                  activityStep === "Online_Marketing_Activities" ? null : (
                    <CustomButton
                      label="Send to Owner"
                      sx={{
                        color: "#0091DF",
                        borderRadius: "28px",
                        border: "1px solid #0091DF",
                        width: "140px",
                      }}
                    ></CustomButton>
                  )}
                  <CustomButton
                    label={activityEnb ? "Submit" : isLast ? "Submit" : "Next"}
                    sx={{
                      color: "#fff",
                      borderRadius: "28px",
                      backgroundColor: "rgb(0, 145, 223)",
                      opacity: "1",
                      "&:hover": {
                        backgroundColor: "#0D47A1",
                      },
                      width: "140px",
                    }}
                    handleClick={() => nextActivity()}
                    className="cus-btn-arrow"
                    labelClassName={"label"}
                  ></CustomButton>
                </Box>
              }
            >
              {renderNext()}
            </MainCard>
          </Grid>
        </Grid>
      </Box>
      <MiModal
        open={open}
        title="Confirmation"
        description="No Trail Found. Are you sure?"
        ok={{ buttonName: "Proceed", ok: modalProceed }}
        cancel={{ buttonName: "Cancel", cancel: modalCancel }}
      />
    </div>
  );
};

export default memo(ActivitiesCriteria);
